<template>
<div>
<b-card no-body>
    <b-card-header>
        <b-card-title>{{$t("itemstock.title")}}</b-card-title>
    </b-card-header>
    <b-card-body>
  <b-form @submit.prevent="insertItemStock">
    <b-row>
      <!-- first name -->
      <b-col  lg="4" md="4" sm="12">
        <b-form-group
          :label="$t('itemstock.itemname')"
        >
          <b-form-input
          v-model="itemstock.title"
            :placeholder="$t('itemstock.itemname')" required
          />
        </b-form-group>
      </b-col>
       <b-col  lg="4" md="4" sm="12">
        <b-form-group
          :label="$t('itemstock.itemnamees')"
        >
          <b-form-input
          v-model="itemstock.titlees"
            :placeholder="$t('itemstock.itemnamees')" required
          />
        </b-form-group>
      </b-col>

      <!-- email -->
      <b-col lg="4" md="4" sm="12">
        <b-form-group
          :label="$t('itemstock.itemimage')"
        >
           <b-form-file
      v-model="imagen"
      :placeholder="$t('itemstock.itemimage')"
      drop-placeholder="Drop file here..." required
      @input="readURL"
        />
        </b-form-group>
      </b-col>

      <b-col lg="6" md="6" cols="12">
        <b-form-group
          :label="$t('itemstock.description')"
        >
        <quill-editor
        required
        style="min-height:150px;" 
        v-model="itemstock.description"
        :placeholder="$t('itemstock.description')"
        :options="editorOption">
        <div
        id="toolbar"
        slot="toolbar">
        <button class="ql-bold">Bold
        </button>
        <button class="ql-italic">
        Italic
        </button>
        <button class="ql-underline">
        underline
        </button>
        <span class="ql-formats">
        <button class="ql-align" value=""></button>
        <button class="ql-align" value="center"></button>
        <button class="ql-align" value="right"></button>
        <button class="ql-align" value="justify"></button>
        </span>
        <button class="ql-header" value="1">
        H1
        </button>
        <button class="ql-header" value="2">
        H2
        </button>
        <button class="ql-list" value="ordered"/>
        <button class="ql-list" value="bullet"/>
        <button class="ql-indent" value="-1"/>
        <button class="ql-indent" value="+1"/>
        <button class="ql-emoji">
        emoji
        </button>



        </div>
        </quill-editor>
             
        </b-form-group>
      </b-col>
       <b-col lg="6" md="6" cols="12">
        <b-form-group
          :label="$t('itemstock.descriptiones')"
        > 
      <quill-editor
        required
        style="min-height:150px;" 
        v-model="itemstock.descriptiones"
        :placeholder="$t('itemstock.descriptiones')"
        :options="editorOptionTwo">
        <div
        id="toolbar2"
        slot="toolbar">
        <button class="ql-bold">Bold
        </button>
        <button class="ql-italic">
        Italic
        </button>
        <button class="ql-underline">
        underline
        </button>
        <span class="ql-formats">
        <button class="ql-align" value=""></button>
        <button class="ql-align" value="center"></button>
        <button class="ql-align" value="right"></button>
        <button class="ql-align" value="justify"></button>
        </span>
        <button class="ql-header" value="1">
        H1
        </button>
        <button class="ql-header" value="2">
        H2
        </button>
        <button class="ql-list" value="ordered"/>
        <button class="ql-list" value="bullet"/>
        <button class="ql-indent" value="-1"/>
        <button class="ql-indent" value="+1"/>
        <button class="ql-emoji">
        emoji
        </button>
        </div>
        </quill-editor>
        </b-form-group>
      </b-col>
      <b-col md="3" sm="12">
        <b-form-group
        :label="$t('itemstock.price')"
        >
           <b-form-input
           type="number"
          v-model="itemstock.price"
            :placeholder="$t('itemstock.price')" required
          />
        </b-form-group>
      </b-col>
     <b-col md="3" sm="12">
        <b-form-group
        :label="$t('itemstock.stock')">
           <b-form-input
           type="number"
          v-model="itemstock.stock"
            :placeholder="$t('itemstock.stock')" required
          />
        </b-form-group>
      </b-col>
      <b-col  md="3" sm="12">
         <b-form-group
         :label="$t('itemstock.selecttype')">
         <b-form-select
      v-model="itemstock.type.value"
      :options="option"
      @change="openModal(itemstock.type.value)"
    />
      </b-form-group>
      </b-col>
        <b-col  md="3" sm="12">
         <b-form-group
         label="Select a Coin">
          <b-form-select
      v-model="itemstock.typeCoin.value"
      :options="optionsCoin"
    />
      </b-form-group>
      </b-col>
      <!-- submit and reset -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"

          class="mr-1"
        >
      {{$t('itemstock.save')}}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
        >
        {{$t('itemstock.reset')}}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
  </b-card-body>
  </b-card>
<b-modal
        v-model="modal"
         ref="modalid"
      title="percentage discount"
      ok-only
      ok-title="Save"
      @ok="savePercentage"
    >
        <b-form-group>
          <label for="email">Amount Discount</label>
          <b-form-input
          v-model="percentage"
            type="number"
            placeholder="Amount discount"
          />
        </b-form-group>
    </b-modal>
    <b-modal
    id="modalRaffle"
      v-model="openRuffle"
         ref="modalruffle"
      title="Ruffle Dates"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      >
      <b-row>
         <b-col cols="12">
          <b-form>
        <b-form-group
        label="Start Date">
          <flat-pickr
          v-model="itemstock.startdate"
        class="form-control"
      placeholder="Start Date"
      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
          />
        </b-form-group>
        <b-form-group
        label="Due Date">
          <flat-pickr
          v-model="itemstock.duedate"
        class="form-control"
      placeholder="Due Date"
      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
    />
        </b-form-group>
        <b-button block variant="primary" type="button" class="mt-2" :disabled="!itemstock.startdate || !itemstock.duedate">
          Save
        </b-button>
        </b-form>
      </b-col>
      </b-row>
    </b-modal>
    <b-modal
    id="modalMB"
    v-model="openMB"
       ref="modalMB"
      title="Create Mystery Box"
      no-close-on-backdrop
      no-close-on-esc
      ok-only
      ok-title="Save"
      :ok-disabled="!itemstock.mysterybox"
      ok
      size="lg">
        <mystery-box-items/>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BCard,BCardHeader,BCardTitle,BCardBody,BFormFile,
  BFormSelect,BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SellerService from '@/services/SellerService'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css' 
import "quill-emoji/dist/quill-emoji.css";
import Quill from 'quill'
 import * as Emoji from "quill-emoji";
Quill.register("modules/emoji", Emoji);
import { quillEditor } from 'vue-quill-editor'
import flatPickr from 'vue-flatpickr-component'
import MysteryBoxItems from "@/components/MysteryBoxItems.vue"
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,BCard,BCardHeader,BCardTitle,BCardBody,BFormFile,vSelect,
  quillEditor,BFormSelect,BModal,flatPickr,MysteryBoxItems
  },
  directives: {
    Ripple,
  },
  data(){
    return{
        itemstock:{
title:"",
titlees:"",
description:"",
descriptiones:"",
price:"",
stock:"",
startdate:null,
duedate:null,
type:{
value:0
},
typeCoin:{
value:1
},
imageUrl:"",
mysterybox:null
},
  editorOption: {
modules: {
toolbar: '#toolbar',
  "emoji-toolbar": true,
    "emoji-textarea": true,
    "emoji-shortname": true,
}},
  editorOptionTwo: {
modules: {
toolbar: '#toolbar2',
  "emoji-toolbar": true,
    "emoji-textarea": true,
    "emoji-shortname": true,
}
},
imagen:null,
imagenFile:null,
      option: [],
 dialogHidden: true,
 modal:false,
 percentage:null,
 openRuffle:false,
 openMB:false,
 optionsCoin:[{text:'Crowns',value:1},{text:'Goblets',value:2}]
    }
  },
  mounted(){
    this.getTypes()
     this.$root.$on("selectedMB", (data) => {
      let sum=0
      this.itemstock.mysterybox=data
      for (let i = 0; i < this.itemstock.mysterybox.length; i++) {
            sum+=this.itemstock.mysterybox[i].amount
      }
      this.itemstock.stock=sum
     
    });

  },
  methods:{
  toogleDialogEmoji() {
  this.dialogHidden = !this.dialogHidden;
  },
  onSelectEmoji(dataEmoji) {
 
   this.moption.description+= dataEmoji.data;
  this.toogleDialogEmoji();
  },

    getTypes:async function(){
      const b=(await SellerService.getTypesItem(JSON.parse(localStorage.getItem("auth")).discordId)).data
      this.option=b
    },

        readURL: function(input) {
      const vm = this;
      if (input) {
        var reader = new FileReader();
        reader.readAsDataURL(input);
        reader.onload = function(e) {
          vm.imagen = e.target.result;
          vm.imagenFile = input;
        };
      }
    },
    insertItemStock:async function(){
      const insertar=(await SellerService.insertItemStock(JSON.parse(localStorage.getItem("auth")).discordId,
        this.itemstock,this.imagenFile))
              this.$swal({
          title: "",
          text: "Item Saved Successfull",
          icon: "success",
           allowOutsideClick: false,
          showClass: {
            popup: "animate__animated animate__fadeIn",
          },
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false,
        }).then(result => {
        if (result.value) {
                this.$router.go(0)
         }
      });
    },
     openModal:function(index){
      if (index==7) {
        this.modal=true  
      }
      if (index==10) {
        this.openRuffle=true
      }
      if (index==11) {
          this.openMB=true
      }
    },
   
    savePercentage:function(){
      if(this.percentage){
        this.itemstock.type.amount=this.percentage
      }
    },
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.ql-container, .ql-editor {
  min-height: inherit;
}
</style>