import axios from "axios";
import FormData from "form-data";
const url = "/api/v1/user";
class UserService {
  static insert(nname,nusuario,nclave,ncode,nrole) {
    let formData = {
      nrole:nrole,
      ncode:ncode,
      nname:nname,
      nuser:nusuario,
      npass:nclave
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";

    return  axios.post(url + "/save", formData,
      { headers: { "Content-Type": "application/json" } }
    );
  }

  static getUsers() {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(url);
        const data = res.data;
        resolve(data);
      } catch (e) {
        reject(e);
      }
    });
  }

  static insertAvatar(user,avatar)
  {
    let formData = new FormData();
    formData.append("user",user);
    if (avatar) {
      formData.append("imagen", avatar, avatar.name);
    }
    return axios.post(url + "/avdiw", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    
  }
  static getTicketsbyItem(user,itemid){
    let formData = {
      user:user,
      itemid:itemid
    } 
    return axios.post("/api/v1/sapr/sxtck", formData,
      {   headers: {
        "Content-type": "application/json; charset=UTF-8"
     }
    }
    );
  }
  static getRemainingTime(user,itemid){
    let formData = {
      user:user,
      itemid:itemid
    } 
    return axios.post("/api/v1/sapr/sdt", formData,
      {   headers: {
        "Content-type": "application/json; charset=UTF-8"
     }
    }
    );
  }

  static getParticipants(user,itemid){
    let formData = {
      user:user,
      itemid:itemid
    } 
    return axios.post("/api/v1/sapr/rfptx", formData,
      {   headers: {
        "Content-type": "application/json; charset=UTF-8"
     }
    }
    );
  }

  static insertMisteryBox(user,mysteryitem){
    let formData={
      user:user,
      mysteryitem:mysteryitem
    }
    return axios.post("/api/v1/strd/msx/cimyb",formData,
    {
      headers:{
        "Content-type": "application/json; charset=UTF-8"
      }
    }
    );
  }

  static getItemsMisteryBox(user){
    let formData={
      user:user
    }
    return axios.put("/api/v1/strd/msx/cimyb",formData,
    {
      headers:{
        "Content-type": "application/json; charset=UTF-8"
      }  
    }
    )
  }
 
}

export default UserService;