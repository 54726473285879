<template>
  <div>
    <ul class="container">
        <div class="row border p-1" style="border-radius:5px;">
      <div class="col-3"><h6 class="text-left">Select</h6></div>
      <div class="col-3"><h6 class="text-left">Title</h6></div>
      <div class="col-3"><h6 class="text-center">Stock</h6></div>
      <div class="col-3"><h6 class="text-center">Quantity</h6></div>
    </div>
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scroll-area"
        v-if="items.length > 0"
      >
        <b-list-group flush v-for="(item, i) in items" :key="i">
          <b-list-group-item
            class="d-flex justify-content-between align-items-center p-1"
          >
          <b-col md="1">
             <b-form-checkbox
              @input="ItemSelected(item)"
             :checked="selectedItems.includes(item)"
               class="custom-control-success"
              />
          </b-col>
            <b-col md="6">
              <h6>{{ item.title }}</h6>
            </b-col>
            <b-col md="1">
              <b-badge variant="light-success" pill class="badge-round">
                {{ item.stock }}
              </b-badge>
            </b-col>
            <b-col md="4">
              <b-form-spinbutton min="1" :max="item.stock" v-model="item.amount"/>
            </b-col>
          </b-list-group-item>
        </b-list-group>
      </vue-perfect-scrollbar>
    </ul>
  </div>
</template>
<script>
import {
  BListGroup,
  BListGroupItem,
  BBadge,
  BFormSpinbutton,
  BRow,
  BCol,
  BFormCheckbox
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import UserService from "@/services/UserService";
export default {
  props: ["details"],
  components: {
    BListGroup,
    BListGroupItem,
    BBadge,
    VuePerfectScrollbar,
    BFormSpinbutton,
    BRow,
    BCol,
    BFormCheckbox
  },

  data() {
    return {
      items: [],
      value: 1,
       selectedItems: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  mounted() {
    this.gwtItemsMisteryBox();
  },
  methods: {
    gwtItemsMisteryBox: async function () {
      const gi = (
        await UserService.getItemsMisteryBox(
          JSON.parse(localStorage.getItem("auth")).discordId
        )
      ).data;
      this.items = gi;
      this.items.forEach(element => {
        element["amount"]=1
      });
    },
     ItemSelected(item) {
      if (this.selectedItems.includes(item)) {
        this.selectedItems = this.selectedItems.filter(i => i !== item)
      } else {
        this.selectedItems.push(item)
      }
      this.$root.$emit("selectedMB",this.selectedItems)
     }
  },
};
</script>
<style>
.ps {
  height: 200px;
}
</style>
